<div class="medication-dialog-cont">
  <div class="close-mark" (click)="closeDialog()"></div>
  <div class="form-field-cont">
    <label>{{'VKIMedication.Illness' | translate}}</label>
    <mat-form-field>
      <mat-select [(value)]="data.medication.value.illness" (selectionChange)='data.illness.illnessSelection(data.index , $event)'
        [disabled]="(data.blockFirst == 2 ? false : (data.medication.value.blocked == 1 || data.medication.value.blocked == 2)) || data.editBlocked">
        <ng-container *ngFor="let illness of data.illness.illnessList">
          <mat-option *ngIf="((illness.translateIllness | translate) !== 'null' && !data.isAdmin && illness.codeTableNumber !== 34)" [value]="illness.codeTableNumber">
             {{illness.translateIllness | translate}}
          </mat-option>
          <mat-option *ngIf="((illness.translateIllness | translate) !== 'null' && data.isAdmin && illness.codeTableNumber !== 34)" [value]="illness.codeTableNumber">
            {{illness.translateIllness | translate}}
          </mat-option>
          <mat-option *ngIf="((illness.translateIllness | translate) === 'null' && data.isAdmin && illness.codeTableNumber !== 34)" [value]="illness.codeTableNumber">
            {{illness.name}}
          </mat-option>
          <mat-option *ngIf="((illness.translateIllness | translate) !== 'null' && illness.codeTableNumber === 34 && data.country === 'DE')" [value]="illness.codeTableNumber">
            {{illness.translateIllness | translate}}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="data.medication.value.illness === 34 && data.country === 'DE'"
      class="small-custom-form text width-90"
      [ngClass]="{'small-error-number-form': data.medication.value.descriptionError}">
      <mat-label>{{'VKIMedication.Description' | translate}}</mat-label>
      <input matInput #description [value]="data.medication.value.description === 'null' ? null : data.medication.value.description" (input)="data.setDescription(data.index, description)" maxlength="30"
      [disabled]="(data.blockFirst == 2 ? false : (data.medication.value.blocked == 1 || data.medication.value.blocked == 2)) || data.editBlocked">
    </mat-form-field>
  </div>

  <div class="form-field-cont">
    <label>{{'VKIMedication.Name' | translate}}</label>
    <mat-form-field>
      <mat-select (mousedown)="data.medication.clearFilter(data.index)"
        [ngClass]="{'critical': data.medication.value.critical === null || data.ruleConfigMap.get('criticalWarning')['state'] === 0 ? false : data.medication.value.critical}"
        [(value)]="data.medication.value.registrationNr" (selectionChange)='data.medication.registrationNrSelection(data.index, $event)'
        [disabled]="(data.blockFirst == 2 ? false : (data.medication.value.blocked == 1 || data.medication.value.blocked == 2)) || data.editBlocked"
        (openedChange)='responsiveUIService.optionOpened($event)'>
        <mat-option>
          <ngx-mat-select-search [placeholderLabel]="'Common.Search' | translate"
            [noEntriesFoundLabel]="'Common.NoResult' | translate" #search (input)="data.medication.filterAntiBiotic(data.index, search)">
          </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let anti of data.medication.filteredAntiBioticListBigArray[data.index] | async" [value]="anti.id">
          <span>{{anti.name}}</span>
        </mat-option>
        <mat-option *ngIf="data.medication.filteredAntiBioticListBigArray[data.index] && (data.medication.filteredAntiBioticListBigArray[data.index] | async).length == 0" disabled>
          {{'Common.NoResult' | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="form-field-cont">
    <label>{{'VKIMedication.RegistrationNr' | translate}}</label>
    <span class="display-text">{{data.medication.value.displayRegistrationNr || '-'}}</span>
  </div>

  <div class="form-field-cont">
    <label>{{'VKIMedication.WaitingTime' | translate}}</label>
    <span class="display-text">{{data.medication.value.legalWaitingTime || '-'}}</span>
  </div>

  <div class="form-field-cont">
    <label>{{'VKIMedication.Amount' | translate}}</label>
    <mat-form-field>
      <input
        matInput
        type="text"
        inputmode="decimal"
        [value]="data.medication.value.amount === 'null' ? '' : data.medication.value.amount | replacePeriod" #amount
        (keydown)="data.decimalInput($event)"
        (input)="data.setAmount(data.index, amount)"
        [disabled]="(data.blockFirst == 2 ? false : (data.medication.value.blocked == 1 || data.medication.value.blocked == 2)) || data.editBlocked">
    </mat-form-field>
  </div>

  <div class="form-field-cont">
    <label>{{'VKIMedication.Unit' | translate}}</label>
    <mat-form-field>
      <mat-select [(value)]="data.medication.value.unit" (selectionChange)='data.unit.unitSelection(data.index , $event)'
        [disabled]="data.blockFirst == 2 ? false : ((data.medication.value.blocked == 1 || data.medication.value.blocked == 2)) || data.editBlocked">
        <mat-option *ngFor="let unit of data.unit.unitList" [value]="unit.name">
          {{unit.translateUnit | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="form-field-cont">
    <label>{{'VKIMedication.StartDate' | translate}}</label>
    <mat-form-field class="date-form">
      <input
        matInput
        [matDatepicker]="picker"
        [placeholder]="'Common.dd-mm-yyyy' | translate"
        [(value)]="data.medication.value.startDateObj"
        #startDate
        [min]="data.dates.minDate"
        [max]="data.dates.maxDate"
        (keydown)="data.dates.dateInput('startDate', $event, data.index)"
        (dateChange)="data.dates.setDate('startDate', $event, data.index)"
        [disabled]="(data.blockFirst == 2 ? false : (data.medication.value.blocked == 1 || data.medication.value.blocked == 2)) || data.editBlocked"
        maxlength="10">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="form-field-cont">
    <label>{{'VKIMedication.EndDate' | translate}}</label>
    <mat-form-field class="date-form">
      <input
        matInput
        [matDatepicker]="picker2"
        [placeholder]="'Common.dd-mm-yyyy' | translate"
        [(value)]="data.medication.value.endDateObj"
        #endDate
        [min]="data.medication.value.minEndDate"
        [max]="data.dates.maxDate"
        (keydown)="data.dates.dateInput('endDate', $event, data.index)"
        (dateChange)="data.dates.setDate('endDate', $event, data.index)"
        [disabled]="!data.medication.value.endDateEditable || data.editBlocked || (data.blockFirst == 2 ? false : data.medication.value.blocked == 2)"
        maxlength="10">
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker [startAt]="data.medication.value.endDateStartAt" #picker2></mat-datepicker>
    </mat-form-field>
  </div>

  <div *ngIf="data.fieldConfigMap.get('administeredBy')['state'] !== 0" class="form-field-cont">
    <label>{{'VKIMedication.AdministeredBy' | translate}}</label>
    <mat-form-field
      [ngClass]="{'small-error-number-form': data.medication.value.administeredByError}"
      class="small-custom-form text width-90">
      <input matInput
             #administeredBy
             [value]="data.medication.value.administeredBy === 'null' ? '' : data.medication.value.administeredBy"
             (input)="data.setAdministeredBy(data.index, administeredBy)"
              maxlength="150"
             [disabled]="(data.blockFirst == 2 ? false : (data.medication.value.blocked == 1 || data.medication.value.blocked == 2)) || data.editBlocked || data.fieldConfigMap.get('administeredBy')['state'] == 1">
    </mat-form-field>
  </div>

  <div *ngIf="data.fieldConfigMap.get('deliveryNote')['state'] !== 0" class="form-field-cont">
    <label>{{'VKIMedication.DeliveryNote' | translate}}</label>
    <mat-form-field
      [ngClass]="{'small-error-number-form': data.medication.value.deliveryNoteError}"
      class="small-custom-form text width-90">
      <input
        matInput
        #deliveryNote
        [value]="data.medication.value.deliveryNote === 'null' ? '' : data.medication.value.deliveryNote" (input)="data.setDeliveryNote(data.index, deliveryNote)"
        maxlength="50"
        [disabled]="(data.blockFirst == 2 ? false : (data.medication.value.blocked == 1 || data.medication.value.blocked == 2)) || data.editBlocked || data.fieldConfigMap.get('deliveryNote')['state'] == 1">
    </mat-form-field>
  </div>

  <div class="action-buttons-cont">
    <button *ngIf="data.medication.value.icon === 'bin' && !data.editBlocked" mat-icon-button (click)="data.deleteMedication(data.index)">
      <i class="material-icons">delete_forever</i>
    </button>
    <button *ngIf="data.medication.value.icon === 'lock' && !data.editBlocked" mat-icon-button>
      <i class="material-icons">lock</i>
    </button>
    <button *ngIf="data.medication.value.icon === 'unlock' && !data.editBlocked" mat-icon-button>
      <i class="material-icons">lock_open</i>
    </button>
    <div class="close-btn">
      <button class="button-green" mat-raised-button (click)="closeDialog()" >
        {{'VKIMobile.Close' | translate}}
      </button>
    </div>
  </div>
</div>
